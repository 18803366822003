.button {
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline flex;
  font-size: 0.875rem;
  font-weight: bolder;
  gap: 0.25rem;
  height: 2.25em;
  justify-content: center;
  padding-block-end: 0.125rem;
  padding-inline: 1.5rem;
}

.button[disabled] {
  opacity: 0.5;
}

.button.default {
  background-color: var(--color-text);
  color: var(--color-primary-shade);
}

.button.hollow {
  box-shadow: inset 0 0 0 0.125rem currentColor;
}

.button.ghost {
}
